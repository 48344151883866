<template>
    <div class="modal-row2 modal-height-max" style="overflow-x: hidden">
        <div class="row gutters">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <div :class="modalNavWidth">
                        <li class="nav-item" role="presentation">
                            <a class="nav-link" :class="{ active: activeTab == 0 }" @click="activeTab = 0"
                               id="allotment-tab"
                               data-toggle="tab" href="#allotment" role="tab" aria-controls="allotment"
                               aria-disabled="true">{{ t('ALLOTMENT.GENERAL') }}</a>
                        </li>
                    </div>
                    <div :class="modalNavWidth">
                        <li class="nav-item" role="presentation">
                            <a class="nav-link" :class="{ active: activeTab == 1 }" @click="activeTab = 1"
                               id="localizations-tab" data-toggle="tab" href="#localizations" role="tab"
                               aria-controls="localizations" aria-disabled="true">{{ t('LOTS.ONUS') }}</a>
                        </li>
                    </div>
                    <div :class="modalNavWidth">
                        <li class="nav-item" role="presentation">
                            <a class="nav-link" :class="{ active: activeTab == 2 }" @click="activeTab = 2"
                               id="dimensions-tab"
                               data-toggle="tab" href="#dimensions" role="tab" aria-controls="dimensions"
                               aria-disabled="true">{{
                                    t('LOTS.DIMENSIONS')
                                }}</a>
                        </li>
                    </div>
                    <div :class="modalNavWidth">
                        <li class="nav-item" role="presentation">
                            <a class="nav-link" :class="{ active: activeTab == 3 }" @click="activeTab = 3"
                               id="description-tab"
                               data-toggle="tab" href="#dimensions" role="tab" aria-controls="dimensions"
                               aria-disabled="true">{{
                                    t('LOTS.DESCRIPTION')
                                }}</a>
                        </li>
                    </div>
                    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2" v-if="this.isAdmin === 1">
                        <li class="nav-item" role="presentation">
                            <a class="nav-link" :class="{ active: activeTab == 4 }" @click="activeTabReadjustment()"
                               id="reajustment-tab"
                               data-toggle="tab" href="#reajustment" role="tab" aria-controls="reajustment"
                               aria-disabled="true">{{
                                    t('LOTS.READJUSTMENT_TITLE')
                                }}</a>
                        </li>
                    </div>
                    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2" v-if="this.isAdmin === 1">
                        <li class="nav-item" role="presentation">
                            <a class="nav-link" :class="{ active: activeTab == 5 }" @click="activeTabReserve()"
                               id="reserves-tab"
                               data-toggle="tab" href="#reserves" role="tab" aria-controls="reserves"
                               aria-disabled="true">{{
                                    t('LOTS.RESERVES')
                                }}</a>
                        </li>
                    </div>
                </ul>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade" :class="{ active: activeTab == 0, show: activeTab == 0 }"
                             id="allotment"
                             role="tabpanel" aria-labelledby="allotment-tab">
                            <div class="row gutters">
                                <div :class="allotmentSelected?.id_configuracao_bd ? 'col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4' : 'col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6'">
                                    <div class="form-group" v-if="allotments">
                                        <label for="allotment_id">{{ t('LOTS.ALLOTMENT') }}: </label>
                                        <select class="form-control selectpicker" v-model="properties.allotment_id"
                                                disabled>
                                            <option :value="allotments.id">{{ allotments.text }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4"
                                     v-if="allotmentSelected?.id_configuracao_bd">
                                    <div class="form-group">
                                        <label for="owner_name">{{
                                                t('PROPERTIES.OWNER_NAME')
                                            }}: </label>
                                        <input type="TEXT" v-model="properties.owner_name" class="form-control"
                                               step=".01" disabled>
                                    </div>
                                </div>
                                <div :class="allotmentSelected?.id_configuracao_bd ? 'col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4' : 'col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6'">
                                    <div class="form-group">
                                        <label for="parceria_name">{{
                                                t('PROPERTIES.PARCERIA_NAME')
                                            }}: </label>
                                        <input type="text" v-model="properties.parceria_name" placeholder="Parceria" class="form-control" id="parceria_name"
                                               disabled>
                                    </div>
                                </div>
                            </div>
                            <div class="row gutters">
                                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                                    <div class="form-group">
                                        <label for="front_size">{{ t('LOTS.FRONT_CONFRONTATION') }}: </label>
                                        <input type="text" v-model="lots.front_confrontation" name="front_confrontation"
                                               class="form-control" disabled>
                                    </div>
                                </div>
                                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                                    <div class="form-group">
                                        <label for="state">{{ t('LOTS.BLOCK') }}: </label>
                                        <input type="text" class="form-control" v-model="properties.block" disabled>
                                    </div>
                                </div>
                                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                                    <div class="form-group">
                                        <label for="name">{{ t('LOTS.LOT') }}: </label>
                                        <input type="text" v-model="properties.name" class="form-control" disabled>
                                    </div>
                                </div>
                            </div>
                            <div class="row gutters">
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                                    <div class="form-group">
                                        <label for="status">{{ t('LOTS.SITUATION') }}: </label>
                                        <select class="form-control selectpicker"
                                                v-model="properties.properties_status_id"
                                                disabled>
                                            <option :value="situation.id" v-for="situation in situations"
                                                    :key="situation.id">
                                                {{ situation.name }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                                    <div class="form-group" v-if="propertyTypes">
                                        <label for="properties_type_id">{{ t('NAV.PROPERTY_TYPES') }}: </label>
                                        <select class="form-control selectpicker"
                                                v-model="properties.properties_type_id"
                                                disabled>
                                            <option :value="propertyType.id" v-for="propertyType in propertyTypes"
                                                    :key="propertyType.id">{{ propertyType.text }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                                    <div class="form-group">
                                        <label for="price">{{ t('PROPERTIES.PRICE') }}: </label>
                                        <input v-model="properties.price" class="form-control" disabled id="price">
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                                    <div class="form-group">
                                        <label for="brokerageValue">{{ t('PROPERTIES.BROKERAGE_VALUE') }}: </label>
                                        <money3 id="brokerageValue" v-model="properties.brokerage_value"
                                                name="price" class="form-control " disabled
                                                v-bind="config"></money3>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                                    <div class="form-group">
                                        <label for="price_coust">{{ t('LOTS.PRICE_COUST') }}: </label>
                                        <input v-model="properties.cost_value" class="form-control" disabled
                                               id="price_coust">
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                                    <div class="form-group">
                                        <label for="total_area">{{ t('LOTS.TOTAL_AREA') }}: </label>
                                        <money3 v-bind="configAreaTotal" v-model="properties.total_area"
                                                class="form-control" id="total_area"></money3>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                                    <div class="form-group">
                                        <label for="metragem_area">{{ t('LOTS.METRAGEM_TOTAL') }}: </label>
                                        <money3 v-bind="configAreaTotal" v-model="properties.metragem_total"
                                                class="form-control" id="metragem_area"></money3>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                                    <div class="form-group">
                                        <label for="ideal_fraction">{{ t('LOTS.IDEAL_FRACTION') }}: </label>
                                        <money3 v-bind="configFraction" v-model="properties.ideal_fraction"
                                                class="form-control" id="ideal_fraction"></money3>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                                    <div class="form-group">
                                        <label for="numero_registro">{{ t('PROPERTIES.NUMERO_REGISTRO') }}: </label>
                                        <input type="TEXT" v-model="properties.numero_registro" class="form-control"
                                               step=".01"
                                               disabled>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                                    <div class="form-group">
                                        <label for="inscricao_cadastral_iptu">{{
                                                t('PROPERTIES.INSCRICAO_CADASTRAL_IPTU')
                                            }}: </label>
                                        <input type="TEXT" v-model="properties.inscricao_cadastral_iptu"
                                               class="form-control"
                                               step=".01" disabled>
                                    </div>
                                </div>
                                <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-12">
                                    <div class="form-group">
                                        <label>{{ t('ALLOTMENT.LATITUDE') }}: </label>
                                        <input type="number" v-model="properties.latitude" class="form-control"
                                               step=".000001"
                                               disabled>
                                    </div>
                                </div>
                                <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-12">
                                    <div class="form-group">
                                        <label for="longitude">{{ t('ALLOTMENT.LONGITUDE') }}: </label>
                                        <input type="number" v-model="properties.longitude" class="form-control"
                                               step=".000001"
                                               disabled>
                                    </div>
                                </div>
                                <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-12 d-flex ">
                                    <div class="custom-control custom-switch align-self-center">
                                        <input type="checkbox" class="custom-control-input"
                                               id="hasbuildg" disabled
                                               v-model="properties.has_building">
                                        <label class="custom-control-label" for="hasbuildg">
                                            {{ t('PROPERTIES.HAS_BUILDINGS') }}
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="row gutters">
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                    <div class="form-group">
                                        <label for="observation">{{ t('LOTS.IMPROVEMENTS') }}:</label>
                                        <textarea class="form-control" id="improvements" rows="4" disabled
                                                  placeholder="Benfeitorias"
                                                  v-model="properties.improvements"></textarea>
                                    </div>
                                </div>
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                    <div class="form-group">
                                        <label for="observation">{{ t('LOTS.OBSERVATION') }}:</label>
                                        <textarea class="form-control" id="observation" rows="4" disabled
                                                  placeholder="Observações"
                                                  v-model="properties.observation"></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" :class="{ active: activeTab == 1, show: activeTab == 1 }"
                             id="localizations" role="tabpanel" aria-labelledby="localizations-tab">
                            <div class="row gutters">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                    <div class="form-group">
                                        <label for="onus_description">{{ t('LOTS.ONUS_DESCRIPTION') }}: </label>
                                        <input type="text" v-model="properties.onus_description" class="form-control"
                                               id="onus_description" placeholder="Titulo"
                                               disabled>
                                    </div>
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                    <div class="form-group">
                                        <label for="onus">{{ t('LOTS.ONUS') }}:</label>
                                        <textarea class="form-control" id="onus" rows="4" disabled
                                                  placeholder="Ônus"
                                                  v-model="properties.onus"></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" :class="{ active: activeTab == 2, show: activeTab == 2 }"
                             id="dimensions"
                             role="tabpanel" aria-labelledby="dimensions-tab">
                            <div class="row gutters">
                                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                                    <div class="form-group">
                                        <label for="front_size">{{ t('LOTS.MEASURE_FRONT') }}: </label>
                                        <input type="text" v-model="lots.front_size" class="form-control" disabled>
                                    </div>
                                </div>
                                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                                    <div class="form-group">
                                        <label for="back_size">{{ t('LOTS.MEASURE_BACK') }}: </label>
                                        <input type="text" v-model="lots.back_size" class="form-control" disabled>
                                    </div>
                                </div>
                                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                                    <div class="form-group">
                                        <label for="chamfer_size">{{ t('LOTS.MEASURE_CHANFER') }}: </label>
                                        <input type="text" v-model="lots.chamfer_size" class="form-control" disabled>
                                    </div>
                                </div>
                            </div>
                            <div class="row gutters">
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                    <div class="form-group">
                                        <label for="right_size">{{ t('LOTS.MEASURE_RIGHT') }}: </label>
                                        <input type="text" v-model="lots.right_size" class="form-control" disabled>
                                    </div>
                                </div>
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                    <div class="form-group">
                                        <label for="left_size">{{ t('LOTS.MEASURE_LEFT') }}: </label>
                                        <input type="text" v-model="lots.left_size" class="form-control" disabled>
                                    </div>
                                </div>
                            </div>
                            <div class="row gutters">
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                    <div class="form-group">
                                        <label for="background_confrontation">{{
                                                t('LOTS.BACKGROUND_CONFRONTATION')
                                            }}: </label>
                                        <input type="text" id="background_confrontation"
                                               v-model="lots.background_confrontation"
                                               name="background_confrontation" class="form-control" disabled>
                                    </div>
                                </div>
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                    <div class="form-group">
                                        <label for="right_confrontation">{{ t('LOTS.RIGHT_CONFRONTATION') }}: </label>
                                        <input type="text" id="right_confrontation" v-model="lots.right_confrontation"
                                               name="right_confrontation" class="form-control" disabled>
                                    </div>
                                </div>
                            </div>
                            <div class="row gutters">
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                    <div class="form-group">
                                        <label for="left_confrontation">{{ t('LOTS.LEFT_CONFRONTATION') }}: </label>
                                        <input type="text" id="left_confrontation" v-model="lots.left_confrontation"
                                               name="left_confrontation" class="form-control" disabled>
                                    </div>
                                </div>
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                    <div class="form-group">
                                        <label for="chamfer_confrontation">{{
                                                t('LOTS.CHAMFER_CONFRONTATION')
                                            }}: </label>
                                        <input type="text" id="chamfer_confrontation"
                                               v-model="lots.chamfer_confrontation"
                                               name="chamfer_confrontation" class="form-control" disabled>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" :class="{ active: activeTab == 4, show: activeTab == 4 }"
                             id="readjustment" role="tabpanel" aria-labelledby="readjustment-tab"
                             v-if="this.isAdmin === 1">
                            <div class="row gutters" v-if="lots.properties?.readjustments?.length > 0">
                                <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2 font-bold" v-if="!widthMd(768)">
                                    <div class="font-weight-bolder mt-2 ml-4 text-left">{{
                                            this.t('READJUSTMENT.TYPE')
                                        }}
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-4 col-4 font-bold">
                                    <div class="font-weight-bolder mt-2 ml-3 text-left">
                                        {{ this.t('READJUSTMENT.PRICE_BEFORE') }}
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-4 col-4 font-bold">
                                    <div class="font-weight-bolder mt-2 ml-3 text-left">
                                        {{ this.t('READJUSTMENT.PRICE_AFTER') }}
                                    </div>
                                </div>
                                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 font-bold">
                                    <div class="font-weight-bolder mt-2 ml-4 text-left">{{
                                            widthMd(768) ? this.t('READJUSTMENT.CREATED_AT_AB') : this.t('READJUSTMENT.CREATED_AT')
                                        }}
                                    </div>
                                </div>
                                <div class="row w-100">
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 w-100"
                                         style="padding-right: 0px">
                                        <div v-for="(iten, key) in lots.properties.readjustments" :key="iten.id"
                                             :class="key != 0 ? 'border-top2': ''" class="w-100 row">
                                            <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2" v-if="!widthMd(768)">
                                                <div class="list-primary  text-left">
                                                    {{ typeConversion(iten.type) }}
                                                </div>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-4 col-4">
                                                <div class="list-primary text-left">
                                                    {{ toCurrency(iten.price_before) }}
                                                </div>
                                            </div>

                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-4 col-4">
                                                <div class="list-primary text-left">
                                                    {{ toCurrency(iten.price_after) }}
                                                </div>
                                            </div>
                                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                                                <div class="list-primary text-left">
                                                    {{ dateFormat(iten.created_at) }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else class="card">
                                <div class="card-body">
                                    <div class="text-center">
                                        <h5>Nenhum Reajuste Foi Encontrado</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade w-100" :class="{ active: activeTab == 5, show: activeTab == 5 }"
                             id="reserves" role="tabpanel" aria-labelledby="reserves-tab"
                             v-if="this.isAdmin === 1">
                            <div class="row gutters h-100 " v-if="lots.properties?.reserves?.length > 0 ">
                                <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2 font-bold">
                                    <div class="font-weight-bolder mt-2 ml-4 text-left">
                                        {{ this.t('LOTS.RESERVES_STATUS') }}
                                    </div>
                                </div>
                                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 font-bold">
                                    <div class="font-weight-bolder mt-2 ml-3 text-left">{{
                                            this.t('LOTS.RESERVE_BROKE')
                                        }}
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3 font-bold">
                                    <div class="font-weight-bolder mt-2 ml-3 text-left">{{
                                            this.t('LOTS.RESERVE_START')
                                        }}
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3 font-bold">
                                    <div class="font-weight-bolder mt-2 ml-3 text-left">{{
                                            this.t('LOTS.RESERVE_FINISH')
                                        }}
                                    </div>
                                </div>
                                <div id="reserveContent" class="row w-100 mt-2"
                                     style="overflow: auto;max-height: 50vh;">
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 w-100"
                                         style="padding-right: 0px">
                                        <div v-for="(iten, key) in lots.properties.reserves" :key="iten.id"
                                             :class="key != 0 ? 'border-top2': ''" class="w-100 row">
                                            <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
                                                <div class="list-primary  text-left">
                                                    {{ iten.reserve_status.name }}
                                                </div>
                                            </div>
                                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                                                <div class="list-primary text-left">
                                                    {{ iten?.realtor?.realtorname ? iten?.realtor?.realtorname : '-' }}
                                                </div>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
                                                <div class="list-primary text-left">
                                                    {{
                                                        dateFormatPermanence(iten.permanences_historic[(iten.permanences_historic.length - 1)].start)
                                                    }}
                                                </div>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
                                                <div class="list-primary text-left">
                                                    {{ dateFormatPermanence(iten.permanences_historic[0].finish) }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else class="card">
                                <div class="card-body">
                                    <div class="text-center">
                                        <h5>Nenhuma Reserva Foi Encontrada</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade w-100" :class="{ active: activeTab == 3, show: activeTab == 3 }"
                             id="description" role="tabpanel" aria-labelledby="reserves-tab">
                            <div class="row gutters">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                    <div class="form-group">
                                        <label for="description">{{ t('LOTS.DESCRIPTION') }}: </label>
                                        <editor :init="configTinyMce" v-model="properties.description"
                                                :disable="true"
                                                tinymce-script-src="https://tiny.r2app.com.br/js/tinymce/tinymce.min.js">
                                        </editor>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer right-content">
                    <div v-if="lots.properties?.readjustments?.length > 0 && (lots.properties?.reserves?.length > 0 && this.isAdmin === 1)">
                        <button type="button" v-if="activeTab != 0" class="btn btn-secondary mr-1" @click="activeTab--">
                            {{ t('ALLOTMENT.PREVIOUS') }}
                        </button>
                        <button type="button" v-if="activeTab != 5"
                                class="btn btn-secondary mr-1" @click="activeTab++">
                            {{ t('ALLOTMENT.NEXT') }}
                        </button>
                    </div>
                    <div v-else-if="this.isAdmin === 1">
                        <button type="button" v-if="activeTab != 0" class="btn btn-secondary mr-1"
                                @click="previousTab()">
                            {{ t('ALLOTMENT.PREVIOUS') }}
                        </button>
                        <button type="button" v-if="activeTab != 5"
                                class="btn btn-secondary mr-1" @click="nextTab()">
                            {{ t('ALLOTMENT.NEXT') }}
                        </button>
                    </div>
                    <div v-else>
                        <button type="button" v-if="activeTab != 0" class="btn btn-secondary mr-1" @click="activeTab--">
                            {{ t('ALLOTMENT.PREVIOUS') }}
                        </button>
                        <button type="button"
                                v-if="activeTab != 3"
                                class="btn btn-secondary mr-1" @click="activeTab++">
                            {{ t('ALLOTMENT.NEXT') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import {useI18n} from "vue-i18n";
import {useToast} from "vue-toastification";
import Editor from '@tinymce/tinymce-vue';
import {Money3Component} from 'v-money3';
import jwt_decode from 'jwt-decode';
import Lots from '@/services/Lots';
import {mapState} from 'vuex';

export default {
    name: 'viewLot',
    props: ['propertiesIndex', 'lotsIndex', 'hastwoAllotments', 'allotmentSelected', 'propertiesStatusIndex'],
    components: {
        Editor,
        money3: Money3Component,
    },

    setup() {
        const toast = useToast();
        const {t} = useI18n();
        return {t, toast}
    },
    computed: {
        modalNavWidth() {
            if (this.isAdmin === 1) {
                return 'col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2';
            }
            return 'col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3';
        },
        ...mapState({
            allotmentsState: 'allotments',
        }),
    },
    data() {
        return {
            propertiesStatus: [],
            configTinyMce: {
                license_key: 'gpl',
                plugins: 'print preview paste searchreplace autolink autosave save directionality visualchars fullscreen link charmap hr pagebreak toc advlist lists wordcount textpattern  quickbars emoticons',
                menubar: 'file edit format',
                toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl',
                template_cdate_format: '[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]',
                template_mdate_format: '[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]',
                height: 300,
                content_css: 'default',
                language: 'pt_BR',
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
            },
            isAdmin: false,
            lots: {},
            activeTab: 0,
            situations: [],
            properties: {},
            allotments: null,
            propertyTypes: null,
            configEditor: {
                height: '200'
            },
            config: {
                min: 0,
                suffix: '',
                masked: true,
                decimal: ',',
                precision: 2,
                prefix: 'R$ ',
                thousands: '.',
                disabled: true,
                allowBlank: false,
                max: 100000000000,
                disableNegative: true,
                minimumNumberOfCharacters: 1
            },
            configFraction: {
                min: 0,
                suffix: '%',
                masked: true,
                decimal: '.',
                precision: 9,
                disabled: true,
                thousands: '',
                max: 100,
                allowBlank: false,
                disableNegative: true,
            },
            configAreaTotal: {
                min: 0,
                suffix: '',
                masked: true,
                decimal: ',',
                precision: 4,
                prefix: '',
                thousands: '.',
                disabled: true,
                allowBlank: false,
                max: 100000000000,
                disableNegative: true,
                minimumNumberOfCharacters: 1
            },
        }
    },

    mounted() {
        this.sheckAdmin();
        this.startModalInfs();
        this.indexPropertyTypes();
        this.propertiesStatus = this.propertiesStatusIndex;
    },

    watch: {
        propertiesIndex: function () {
            this.updateModalInfs();
        }
    },

    methods: {
        previousTab(){
            this.activeTab--
            if(this.activeTab === 4){
                this.activeTabReadjustment();
            }
            if(this.activeTab === 5){
                this.activeTabReserve();
            }
        },
        nextTab(){
            this.activeTab++
            if(this.activeTab === 4){
                this.activeTabReadjustment();
            }
            if(this.activeTab === 5){
                this.activeTabReserve();
            }
        },
        activeTabReserve() {
            this.activeTab = 5
            if (!this.lots.properties?.reserves?.length) {
                this.$store.commit('changeLoading', true);
                Lots.showOnlyReserve(this.lots.id).then(resp => {
                    if (resp.data?.length){
                        this.lots.properties.reserves = resp.data;
                    }else {
                        this.lots.properties.reserves = [];
                    }
                }).catch(() => this.lots.properties.reserves = [])
                        .finally(() => this.$store.commit('changeLoading', false));
            }
        },
        activeTabReadjustment() {
            this.activeTab = 4
            if (!this.lots.properties?.readjustments?.length) {
                this.$store.commit('changeLoading', true);
                Lots.showOnlyReadjustment(this.lots.id).then(resp => {
                    if (resp.data?.length){
                        this.lots.properties.readjustments = resp.data;
                    }else {
                        this.lots.properties.readjustments = [];
                    }
                }).catch(() => this.lots.properties.readjustments = [])
                        .finally(() => this.$store.commit('changeLoading', false));
            }
        },
        startModalInfs() {
            this.lots = this.lotsIndex;
            this.properties = this.propertiesIndex;
            if (this.properties.brokerage_value) this.properties.brokerage_value = parseFloat(this.properties.brokerage_value).toFixed(2);
            if (this.properties.metragem_total) this.properties.metragem_total = parseFloat(this.properties.metragem_total).toFixed(4);
            if (this.properties.total_area) this.properties.total_area = parseFloat(this.properties.total_area).toFixed(4);
            if (this.properties.ideal_fraction) this.properties.ideal_fraction = parseFloat(this.properties.ideal_fraction).toFixed(9);
            this.properties.price = this.toCurrency(this.properties.price)
            this.properties.cost_value = Intl.NumberFormat('pt-br', {
                style: 'currency',
                currency: 'BRL'
            }).format(this.properties.cost_value);
            let statusP = this.propertiesStatusIndex.find(i => i.id === this.properties.properties_status_id);
            if (statusP.can_select){
                let statusTrue = this.propertiesStatusIndex.filter(i => i.can_select);
                this.properties.properties_status = statusTrue;
            }else {
                this.properties.properties_status = [statusP]
            }
            this.situations = this.properties.properties_status;
            if (this.allotmentsState?.length){
                this.allotments = this.allotmentsState.filter(i => i.id === this.propertiesIndex.allotment_id)[0];
            }
            this.properties.description = this.properties.description ? this.properties.description : null;
        },
        sheckAdmin() {
            let companies = jwt_decode(localStorage.getItem('access_token'))
                    .roles['companies'];
            companies.forEach(company => {
                company.companies.forEach(id => {
                    if (localStorage.getItem('companyId') === id) {
                        this.isAdmin = company.group_id;
                    }
                })
            })
        },
        widthMd(size = 1025, smaller = '<') {
            if (smaller == '<') {
                return window.innerWidth < size;
            } else {
                return window.innerWidth > size;
            }
        },
        dateFormat(value) {
            let dateAndTime = value.split('T');
            let date = dateAndTime[0].split('-');
            let time = dateAndTime[1].split('.');
            if (!this.widthMd(768)) {
                return `${date[2]}/${date[1]}/${date[0]} ás ${time[0]}`;
            } else return `${date[2]}/${date[1]}/${date[0]}`;

        },
        dateFormatPermanence(value) {

            if (value) {
                let onlyData = value.split(' ');
                let data = onlyData[0].split('-');

                value = data[2] + '/' + data[1] + '/' + data[0] + ' ás ' + onlyData[1];

                return value;
            } else {
                return '?';
            }
        },
        updateModalInfs() {
            this.activeTab = 0;
            this.indexPropertyTypes();
            this.lots = this.lotsIndex;
            this.properties = this.propertiesIndex;
            if (this.properties.brokerage_value) this.properties.brokerage_value = parseFloat(this.properties.brokerage_value).toFixed(2);
            if (this.properties.metragem_total) this.properties.metragem_total = parseFloat(this.properties.metragem_total).toFixed(4);
            if (this.properties.total_area) this.properties.total_area = parseFloat(this.properties.total_area).toFixed(4);
            if (this.properties.ideal_fraction) this.properties.ideal_fraction = parseFloat(this.properties.ideal_fraction).toFixed(9);
            this.properties.price = this.toCurrency(this.properties.price)
            this.properties.cost_value = Intl.NumberFormat('pt-br', {
                style: 'currency',
                currency: 'BRL'
            }).format(this.properties.cost_value);
            let statusP = this.propertiesStatusIndex.find(i => i.id === this.properties.properties_status_id);
            if (statusP.can_select){
                let statusTrue = this.propertiesStatusIndex.filter(i => i.can_select);
                this.properties.properties_status = statusTrue;
            }else {
                this.properties.properties_status = [statusP]
            }
            this.situations = this.properties.properties_status;
            this.allotments = this.allotmentsState.filter(i => i.id === this.propertiesIndex.allotment_id)[0];
            this.properties.description = this.properties.description ? this.properties.description : null;
            this.propertiesStatus = this.propertiesStatusIndex;
        },
        toCurrency(value) {
            if (value === null) return "Indisponível";
            return Intl.NumberFormat('pt-br', {style: 'currency', currency: 'BRL'}).format(value);
        },
        indexPropertyTypes() {
            this.propertyTypes = this.propertiesIndex.properties_types;
        },
        typeConversion(type) {
            if (type === 0) return 'Reais';
            if (type === 1) return 'Porcentagem'
            return 'Metro'
        },
    }
}
</script>
<style>
.modal {
    position: absolute;
}

#reserveContent::-webkit-scrollbar {
    width: 4px;
}

#reserveContent::-webkit-scrollbar-thumb {
    border-radius: 5px;
    border: 3px solid;
}
</style>
