<template>
    <div>
        <page-header v-if="!hasCreatePermission" pageTitle="LOTS.INDEX_TITLE"/>
        <page-header v-if="hasCreatePermission" btnNew="ACTIONS.NEW" pageTitle="LOTS.INDEX_TITLE"
                     @newType="setRegisterModal"/>
        <div class="card paddin">
            <div class="row gutters">
                <div :class="hasCreatePermission ? 'col-xl-10 col-lg-10 col-md-10 col-sm-10 col-10':
               'col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                    <div class="tags-block mt-1 ml-2 row left-content" v-if="subtitles">
                        <div class="tags" v-for="sub in subtitles" :key="sub.id">
                            <div class="mr-2">
                                <i class="icon-label" :style="{'color': sub.backgroud_collor}"></i> {{ sub.name }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2" v-if="hasCreatePermission">
                    <div class="text-right mr-3">
                        <button data-cy="lot_index_dropdown" class="btn btn-custom bg-btn no-border" type="button"
                                id="import-buttom"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="icon-menu"/>
                        </button>
                        <div class="dropdown-menu dropdown-menu-right dropdown-black dropdown-lot" id="dropdown-import">
                            <div data-cy="lot_index_button_import" class="dropdown-item bg-hover-black"
                                 @click="uploadFile = true">
                                <div class="row item-custom2 ml-1 text-white ">
                                    <i class="icon-folder_open mr-2 font-15"/>{{ t('GENERAL.MSG.IMPORT_FILE') }} XLSX
                                </div>
                            </div>
                            <div class="dropdown-item bg-hover-black">
                                <a class="row item-custom2 ml-1 text-white" @click="exportLot">
                                    <i class="icon-file mr-2 font-15"/>{{ t('PROPERTIES.EXPORT_FILE_LOT') }} XLSX
                                </a>
                            </div>
                            <div class="dropdown-item bg-hover-black">
                                <a class="row item-custom2 ml-1 text-white" href="Modelo para Importação Lote.xlsx"
                                   download>
                                    <i class="icon-arrow-with-circle-down mr-2 font-15"/>{{
                                        t('GENERAL.MSG.DOWNLOAD_FILE_EXEMPLE')
                                    }}
                                </a>
                            </div>
                            <div class="dropdown-item bg-hover-black" v-if="hasReadjustmentPermission">
                                <a class="row item-custom2 ml-1 text-white" data-target="#customModalTwo"
                                   data-toggle="modal"
                                   @click="setModalReadjustment">
                                    <i class="icon-local_atm mr-2 font-15"/>{{ t('LOTS.APPLY_VALUE_READJUSTMENT') }}
                                </a>
                            </div>
                            <div class="dropdown-item bg-hover-black" v-if="hasReadjustmentPermission">
                                <a class="row item-custom2 ml-1 text-white" data-target="#customModalTwo"
                                   data-toggle="modal"
                                   @click="setModalBlockLiberty">
                                    <i class="icon-pencil mr-2 font-15"/>{{ t('LOTS.ALTER_STATUS_LOTS') }}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card" v-if="hastwoAllotments">
            <div class="card-header">
                <div class="card-title">
                    <label for="state">{{ t('NAV.ALLOTMENT') }}</label>
                </div>
            </div>
            <div class="card-body pt-0">
                <Select2 :settings="{ width: widthMd(1000) ? '100%' :'50%', placeholder: 'Selecione um Loteamento' }" id="allotment"
                         name="allotment" v-model="allotment_id"
                         :options="allotments" @select="changeAllotment($event)"/>
            </div>
        </div>

        <div v-for="(block, key) in blocks" :key="block">
            <div class="accordion toggle-icons" id="toggleIcons" v-if="hasBlocks">
                <div class="accordion-container">
                    <div class="accordion-header" :id="'toggleIcons' + block.title">
                        <a data-toggle="collapse" :data-target="'#toggleIconsCollapse' + block.title"
                           :aria-expanded="key == 0 ? true: false"
                           :aria-controls="'toggleIconsCollapse' + block.title"
                           @click="indexLotsByAllotment(allotment_id, block.text)">
                            {{ t('PROPERTIES.BLOCK') }} {{ block.text }}
                        </a>
                    </div>
                    <div :id="'toggleIconsCollapse' + block.title" class="collapse"
                         :class="{show: block.text == blockOpen}"
                         :aria-labelledby="'toggleIcons' + block.title" :data-parent="'#toggleIcons' + block.title">
                        <div class="accordion-body">
                            <div class="text-center"
                                 v-if="isLoadingLots && (isLoadingLots.block == block.text && isLoadingLots.isLoading)">
                                <div class="spinner-border m" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </div>
                            <div class="row gutters">
                                <div v-for="lot in lots[block.text]" :key="lot.id"
                                     class="col-xl-1 col-lg-4 col-md-6 col-sm-6 col-6 text-center">
                                    <div class="card" :id="lot.id"
                                         :style="{'background-color': lot.status.backgroud_collor}"
                                         v-if="hasShowPermission">
                                        <a data-cy="lot_index_modal" href="#" data-target="#modalShow"
                                           data-toggle="modal"
                                           @click="setShowModal(lot)">
                                            <h3 class="mt-1" :style="{'color': lot.status.text_collor}">{{
                                                    lot.name
                                                }}</h3>
                                            <p class="hide-text" :style="{'color': lot.status.text_collor}">
                                                {{ lot.properties_type_name }}</p>
                                            <p :style="{'color': lot.status.text_collor}">{{ lot.total_area }} m²</p>
                                            <p class="font-weight-bold" :style="{'color': lot.status.text_collor}">
                                                {{ toCurrency(lot.price) }}</p>
                                        </a>
                                    </div>
                                    <div class="card" :id="lot.id"
                                         :style="{'background-color': lot.status.backgroud_collor}"
                                         v-if="!hasShowPermission">
                                        <h3 class="mt-1" :style="{'color': lot.status.text_collor}">{{ lot.name }}</h3>
                                        <p :style="{'color': lot.status.text_collor}">{{ lot.total_area }} m²</p>
                                        <p class="font-weight-bold" :style="{'color': lot.status.text_collor}">
                                            {{ toCurrency(lot.price) }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card" v-if="!hasBlocks || !allotments">
            <div class="card-header"></div>
            <div class="card-body pt-0">
                <div class="text-center">
                    <h5>{{ t('GENERAL.MSG.NO_REGISTER', {name: t('LOTS.INDEX_TITLE'), os: 'os'}) }}</h5>
                </div>
            </div>
        </div>

        <modal :modal-extra-large="whichModal === 4"
               :modal-xl="whichModal !== 4"
               :titleModal="modalTitle"
               @close="closeModal">
            <template v-slot:title>{{ t(modalTitle) }}</template>

            <create-lot v-if="whichModal === 1 && (hastwoAllotments && allotments || !hastwoAllotments && allotment_id)"
                        :hastwoAllotments="hastwoAllotments" :isEdit="isEdit"
                        :properties-status-index="propertiesStatus"
                        :propertiesIndex="properties" :lotsIndex="lotsIndex"
                        :allotments="hastwoAllotments ? allotments : allotment_id" @created="afterCreate($event)"
                        :allotmentSelected="allotmentSelect"
                        @edited="afterEdit($event)">
            </create-lot>

            <create-reserve v-if="whichModal === 2 && reserve && (reserveTypesIndex && reserveTypesIndex[0])"
                            :reserveIndex="reserve" idModal="modalReserve"
                            :allotmentsIndex="allotments" :blocksIndex="blocks" :isRealtor="hasConfirmPermission"
                            :reserveTypesIndex="reserveTypesIndex" @created="updateAfterCreated($event)">
            </create-reserve>

            <view-lot v-if="whichModal === 3 && properties.id && lotsIndex" :propertiesIndex="properties"
                      :properties-status-index="propertiesStatus"
                      :lotsIndex="lotsIndex" :hastwoAllotments="hastwoAllotments" :allotmentSelected="allotmentSelect">
            </view-lot>
            <iframe :src="srcIframe" style="height: 80vh!important;" v-if="whichModal === 4 && srcIframe"/>
            <div class="modal-footer right-content" v-if="whichModal === 4">
                <button data-cy="lot_index_simulate_close" class="btn btn-secondary" data-dismiss="modal" type="button">
                    {{ t('ACTIONS.CLOSE') }}
                </button>
            </div>

            <readjustment-lot-component v-if="whichModal === 5" :allotmentIndex="allotments"
                                        :allotmentIdIndex="allotment_id"
                                        :properties-type="propertiesType"
                                        :properties-status="propertiesStatus"
                                        @Readjusted="indexAllotment">
            </readjustment-lot-component>
            <alter-status-lot-in-mass-component v-if="whichModal === 6" :allotmentIndex="allotments"
                                                :status-to="status_to" :status-from="status_from"
                                                :allotmentIdIndex="allotment_id" :properties-status="propertiesStatus"
                                                :properties-type="propertiesType"
                                                @updated="indexAllotment"></alter-status-lot-in-mass-component>
        </modal>

        <show-lot v-if="modalTitle === 'LOTS.LOT_INFORMATION'" :lotsIndex="lotsIndex" :modalTitle="modalTitle"
                  @update="setEditModal($event)"
                  @view="setViewModal($event)"
                  @delete="setDelete($event)"
                  @reserve="setRegisterModalReserve($event)"
                  @simulate="setSimulateModal">
        </show-lot>
        <upload-modal :uploadFile="uploadFile" @upload="uploadFile = $event"
                      @import="importFile($event)"></upload-modal>
        <modal-select-allotment v-if="allotments" :showModal="showAllotmentSelect"
                                @showModal="showAllotmentSelect = $event"
                                @allotmentSelected="allotmentSelected"></modal-select-allotment>
    </div>
</template>

<script>

import {mapState} from 'vuex';
import {useI18n} from 'vue-i18n';
import Lots from '@/services/Lots';
import User from '@/services/user';
import Modal from '@/components/modal';
import {useToast} from 'vue-toastification';
import Select2 from 'vue3-select2-component';
import viewLot from './viewLotComponent.vue';
import showLot from './showLotComponent.vue';
import Properties from '@/services/Properties';
import Validate from '@/components/validate.vue';
import createLot from './createLotComponent.vue';
import ReserveTypes from '@/services/ReserveTypes';
import Allotments from '../../services/Allotments';
import Permission from '@/components/permission.vue';
import pageHeader from '@/components/layouts/pageHeader';
import propertyTypes from '../../services/propertyTypes';
import uploadModal from '@/components/layouts/uploadModal';
import BuildSimulatorUrl from '@/Helpers/BuildSimulatorUrl';
import createReserve from '../reserve/createReserveComponent';
import readjustmentLotComponent from './readjustmentLotComponent';
import modalSelectAllotment from '../allotment/modalSelectAllotmentComponent.vue';
import alterStatusLotInMassComponent from './alterStatusLotInMassComponent';
import widthMd from '../../mixins/widthMd';

export default {
    name: 'indexLot',

    mixins: [Permission, Validate, BuildSimulatorUrl, widthMd],

    components: {
        Modal,
        Select2,
        viewLot,
        showLot,
        createLot,
        pageHeader,
        uploadModal,
        createReserve,
        modalSelectAllotment,
        readjustmentLotComponent,
        alterStatusLotInMassComponent,
    },

    setup() {
        const toast = useToast();
        const {t} = useI18n();
        return {t, toast};
    },

    computed: {
        ...mapState({
            allotments: 'allotments',
            lotModified: 'lotModified',
            allotmentLoad: 'allotmentLoad',
            hastwoAllotments: 'hastwoAllotments',
        }),
    },

    data() {
        return {
            id: null,
            lots: [],
            name: null,
            blocks: null,
            isEdit: null,
            reserve: null,
            lotsIndex: {},
            modalTitle: '',
            properties: {},
            srcIframe: null,
            blockOpen: null,
            allotment: null,
            subtitles: null,
            hasBlocks: false,
            whichModal: null,
            uploadFile: false,
            allotment_id: null,
            propertiesType: [],
            allotmentSelect: {},
            isLoadingLots: null,
            propertiesStatus: [],
            status_from: [],
            status_to: [],
            reserveTypesIndex: null,
            hasShowPermission: false,
            showAllotmentSelect: false,
            hasCreatePermission: false,
            hasConfirmPermission: false,
            hasReadjustmentPermission: false,
        };
    },

    async mounted() {
        await this.listPropertyesType();
        await this.listPropertiesStatus();
        this.indexAllotment();
        this.indexSituation();
    },

    watch: {
        lotModified: function () {
            if (this.lotModified[0]) {
                this.lotModified.forEach(lot => {
                    this.lots[lot.block]?.forEach((l, key) => {
                        if (l.name == lot.name) {
                            this.lots[lot.block][key] = lot;
                        }
                    });
                });
            }
        },

        allotments: function () {
            this.indexAllotment();
        },
    },

    methods: {
        async listPropertyesType() {
            await propertyTypes.list().then(resp => this.propertiesType = resp.data);
        },

        closeModal()
        {
            this.whichModal = null;
            this.isEdit = false;
            this.properties = {};
            this.lotsIndex = {};
            this.modalTitle = '';
        },

        async listPropertiesStatus() {
            await Properties.listStatusComplet().then(resp => {
                this.propertiesStatus = resp.data;
                this.propertiesStatus.forEach(i => i.text = i.name);
            });
        },

        async indexAllotment(updateBlock = false) {
            this.showAllotmentSelect = this.hastwoAllotments && (localStorage.getItem('allotmentId') ? false : true);

            if (this.allotmentLoad) {
                if (this.hastwoAllotments == false) {
                    this.allotment_id = this.allotments[0].id;
                    this.allotmentSelect = this.allotments[0];
                    this.startLot(this.allotment_id, updateBlock);

                } else {
                    this.allotment_id = localStorage.getItem('allotmentId');
                    if (this.allotment_id) {
                        this.allotmentSelect = this.allotments.find(item => item.id === this.allotment_id);
                    }
                    this.startLot(this.allotment_id, updateBlock);
                }
            }
        },

        exportLot() {
            this.$store.commit('changeLoading', true);
            Lots.export(this.allotment_id).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'lotes.xlsx');
                link.click();
                this.$store.commit('changeLoading', false);
            });
        },

        setModalReadjustment() {
            this.modalTitle = 'LOTS.VALUE_READJUSTMENT';
            this.whichModal = 5;
        },

        setModalBlockLiberty() {
            this.status_from = [];
            this.status_to = [];
            this.propertiesStatus.forEach(i => {
                if (i.can_select) {
                    this.status_to.push(i);
                }
                if (i.type === 4 || i.type === 0) {
                    this.status_from.push(i);
                }
            });
            this.modalTitle = 'LOTS.ALTER_STATUS_LOTS';
            this.whichModal = 6;
        },

        changeAllotment({id}) {
            this.$store.commit('changeLoading', true);
            localStorage.removeItem('allotmentId');
            localStorage.setItem('allotmentId', id);
            this.allotmentSelect = this.allotments.find(item => item.id === id);
            this.$router.go();
        },

        startLot(allotment_id, updateBlock) {
            if (allotment_id) {
                this.permissionsForProfile(allotment_id);
                this.indexBlocks(allotment_id, updateBlock);
                this.indexReserveTypeIndex(allotment_id);
            }

            this.startPermissons();
        },

        permissionsForProfile(allotment_id) {
            try {
                User.permissions(allotment_id).then(resp => {
                    for (let id in resp.data) {
                        if (resp.data[id] === 'Reserve:confirm') {
                            this.hasConfirmPermission = true;
                        }
                    }
                }).catch(() => {});
            } catch (e) {
                console.log();
            }
        },

        allotmentSelected() {
            this.allotment_id = localStorage.getItem('allotmentId');
            this.allotmentSelect = this.allotments.find(item => item.id === this.allotment_id);
            this.startLot(this.allotment_id, true);
        },

        async indexBlocks(allotment_id, updateBlock = false, stopLoopLots) {
            this.$store.commit('changeLoading', true);
            if (stopLoopLots) {
                this.$store.dispatch('ActionListLotModified', stopLoopLots);
            }

            this.blocks = null;
            this.hasBlocks = null;
            this.blockOpen = null;

            await Lots.blocksList(allotment_id).then(resp => this.blocks = resp.data).catch(err => this.errorMsg(err));

            if (!this.blocks.validations) {
                this.hasBlocks = true;
                this.listLotsModified();

                this.blocks.forEach(block => block.title = block.text.replaceAll(' ', ''));
                this.blockOpen = this.blocks[0].text;

                this.indexLotsByAllotment(allotment_id, this.blockOpen, updateBlock);
            }

            this.$store.commit('changeLoading', false);
        },

        indexLotsByAllotment(allotment_id, block_id, updateBlock = false) {
            if (!this.lots[block_id] || updateBlock) {
                this.isLoadingLots = {block: block_id, isLoading: true};
                Lots.lotsByAllotmentAndBlock(allotment_id, block_id)
                    .then(resp => {
                        if (resp.data?.lots?.length) {
                            const lots = resp.data.lots;
                            lots.forEach(i => {
                                i.status = this.propertiesStatus.find(s => s.id === i.properties_status_id);
                                i.properties_type_name = this.propertiesType.find(t => t.id === i.properties_type_id).text;
                            });
                            this.lots[block_id] = lots;
                        } else {
                            this.lots[block_id] = [];
                        }
                    }).catch(error => this.errorMsg(error)).finally(() => this.isLoadingLots.isLoading = false);
            }
        },

        afterEdit() {
            this.$router.go();
        },

        afterCreate(properties) {
            const block_id = properties.properties.block;
            const allotment_id = properties.properties.allotment_id;
            this.updateLots(allotment_id, block_id, false);
        },

        async setShowModal(lots) {
            this.$store.commit('changeLoading', true);
            this.modalTitle = 'LOTS.LOT_INFORMATION';
            await Allotments.showOnlyBd(lots.allotment_id).then(resp => lots.allotments = resp.data);
            this.$store.commit('changeLoading', false);
            this.lotsIndex = lots;
        },

        base64_encode(string) {
            if (string) {
                return btoa(unescape(encodeURIComponent(string)));
            }

            return string;
        },

        async setSimulateModal(lot, type) {
            this.whichModal = 4;
            document.getElementById('closeModal').click();
            this.modalTitle = type === 'simulate' ? 'LOTS.MODAL_TITLE.SIMULATE' : 'LOTS.CREATE_PROPOSE';

            await this.buildSimulatorUrl(lot.lot.property_id).then(url => this.srcIframe = url).catch(err => this.errorMsg(err));
        },

        setViewModal(lote_id) {
            this.whichModal = 3;
            this.$store.commit('changeLoading', true);
            this.modalTitle = 'LOTS.MODAL_TITLE.VIEW';
            document.getElementById('closeModal').click();

            Lots.show(lote_id).then(resp => {
                let statusP = this.propertiesStatus.find(i => i.id === resp.data.properties.properties_status_id);
                if (statusP.can_select) {
                    const statusTrue = this.propertiesStatus.filter(i => i.can_select);
                    resp.data.properties.properties_status = statusTrue;
                } else {
                    resp.data.properties.properties_status = [statusP];
                }
                resp.data.properties.properties_types = this.propertiesType;
                this.properties = resp.data.properties;
                this.lotsIndex = resp.data;
            }).catch(error => this.errorMsg(error))
                .finally(() => this.$store.commit('changeLoading', false));
        },

        async setEditModal(lote_id) {
            document.getElementById('closeModal').click();
            this.isEdit = true;
            this.whichModal = 1;
            this.$store.commit('changeLoading', true);

            await Lots.show(lote_id).then(resp => {
                let statusP = this.propertiesStatus.find(i => i.id === resp.data.properties.properties_status_id);
                if (statusP.can_select) {
                    let statusTrue = this.propertiesStatus.filter(i => i.can_select);
                    resp.data.properties.properties_status = statusTrue;
                } else {
                    resp.data.properties.properties_status = [statusP];
                }
                resp.data.properties.properties_types = this.propertiesType;
                this.properties = resp.data.properties;
                this.lotsIndex = resp.data;
                this.properties.allotments = null;
                this.modalTitle = 'LOTS.MODAL_TITLE.EDIT';
            }).catch(error => this.errorMsg(error))
                .finally(() => this.$store.commit('changeLoading', false));
        },

        setRegisterModal() {
            this.whichModal = 1;
            if (!this.hastwoAllotments && !this.allotments) {
                this.$store.dispatch('ActionUpdateMenu', 'allotment', false);
                this.$store.commit('displayMenssageAllotmentRequired', true);
                this.$router.push({name: 'IndexAllotmant'});
            } else {
                this.isEdit = false;
                this.properties = {
                    name: null,
                    block: null,
                    price: null,
                    status: null,
                    latitude: null,
                    longitude: null,
                    total_area: null,
                    description: null,
                    properties_type_id: null,
                    allotment_id: this.allotment_id,
                };

                this.lotsIndex = {
                    left_size: null,
                    back_size: null,
                    front_size: null,
                    right_size: null,
                    chamfer_size: null,
                    front_confrontation: null,
                    background_confrontation: null,
                    right_confrontation: null,
                    left_confrontation: null,
                    chamfer_confrontation: null,
                };

                this.modalTitle = 'LOTS.MODAL_TITLE.ADD';
            }
        },

        startPermissons() {
            this.hasShowPermission = this.validatePermissions('Lot:show');
            this.hasCreatePermission = this.validatePermissions('Lot:create');
            this.hasReadjustmentPermission = this.validatePermissions('Readjustment:property');
        },

        indexSituation() {
            Properties.indexSituation().then(resp => this.subtitles = resp.data.data);
        },

        setDelete(properties) {
            this.$swal.fire({
                title: this.t('GENERAL.MSG.REALLY_WANT_DELETE'),
                showCancelButton: true,
                showCloseButton: true,
                confirmButtonText: `Excluir`,
                cancelButtonText: 'Cancelar',
            }).then(result => {
                if (result.isConfirmed) {
                    this.$store.commit('changeLoading', true);

                    Lots.delete(properties.id).then(() => {
                        const block_id = properties.block;
                        this.updateLots(this.allotment_id, block_id, true);

                        this.toast.success(this.t('GENERAL.MSG.DELETED_SUCCESS'));

                        document.getElementById('closeModal').click();
                    }).catch(error => this.errorMsg(error))
                        .finally(() => this.$store.commit('changeLoading', false));
                }
            });
        },

        updateLots(allotment_id, block, isDelete) {
            let blocksLength = 0;
            let updateWholeBlock = true;

            if (this.blocks.validations) {
                this.indexAllotment(true);
            } else {
                if (this.blocks.find(b => b.text == block)) {
                    updateWholeBlock = false;
                }

                if (isDelete) {
                    for (let id in this.lots) {
                        if (block == id) {
                            blocksLength = this.lots[id].length;
                        }
                    }
                }

                if ((updateWholeBlock && !isDelete) ||
                    (blocksLength == 1 && isDelete)) {
                    this.indexBlocks(allotment_id, true);

                } else {
                    this.indexLotsByAllotment(allotment_id, block, true);
                }
            }
        },

        listLotsModified() {
            this.$store.dispatch('ActionListLotModified');
        },

        toCurrency(value) {
            if (value === null) return 'Indisponível';
            return Intl.NumberFormat('pt-br', {style: 'currency', currency: 'BRL'}).format(value);
        },

        setRegisterModalReserve(lot) {
            this.allotment = [];
            this.whichModal = 2;
            document.getElementById('closeModal').click();
            this.allotment.push(this.allotments.find(al => al.id == this.allotment_id));

            this.reserve = {
                block: 1,
                clients: [],
                realtors: [],
                observation: '',
                blocks: lot.block,
                isLotOrigin: true,
                reserve_type_id: null,
                allotments: this.allotment,
                property_id: lot.lot.property_id,
                allotment_id: this.allotment_id,
                lots: [{id: lot.id, text: lot.name}],
            };

            if (!this.hasConfirmPermission && !this.reserveTypesIndex[0]) {
                this.toast.error(this.t('GENERAL.MSG.DONT_HAVE_RESERVE_TYPE_REGISTER'), {timeout: false});
            }

            this.modalTitle = 'RESERVE.MODAL_TITLE.ADD';
        },

        updateAfterCreated(reserve) {
            const allotment_id = reserve.allotment_id;
            const block_id = reserve.block_id;
            this.indexLotsByAllotment(allotment_id, block_id, true);
            this.reserve = null;
        },

        indexReserveTypeIndex(allotment_id) {
            if (allotment_id) {
                ReserveTypes.list(allotment_id)
                    .then(resp => this.reserveTypesIndex = resp.data)
                    .catch(err => this.errorMsg(err));
            }
        },

        importFile(file) {
            this.$store.commit('changeLoading', true);

            Lots.import(file, this.allotment_id).then(resp => {
                this.updateLots(this.allotment_id, resp.data);
                this.toast.success(this.t('GENERAL.MSG.LOTS_IMPORT_WITH_SUCCESSS'));
            }).catch(error => this.errorMsg(error)).finally(() => {
                this.uploadFile = false;
                this.$store.commit('changeLoading', false);
            });
        },
    },
};
</script>

<style>
.paddin {
    padding-left: 1rem;
    padding-top: 0.75rem;
    padding-bottom: 0.5rem;
}

.no-border {
    border: 0px solid #0e5e4e;
}

.dropdown-black {
    background: #333333;
}

.text-white {
    color: white;
}

.font-15 {
    font-size: 15px;
}

.dropdown-lot {
    width: 245px;
    left: 4px !important;
}

.dropdown-lot::before {
    border-bottom: 9px solid #333333;
    opacity: 0.9;
}

.red {
    color: red;
}

.item-custom2 {
    padding-top: 6px;
    padding-bottom: 2px;
}
</style>
