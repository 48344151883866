<template>
    <div>
        <div class="modal-row2 modal-height-max">
            <div class="modal-body modal-row2 card" style="min-height: 60vh;">
                <div class="card pl-3 pt-2 mt-2 pr-3 pb-1">
                    <div class="row">
                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12 mb-2">
                            <label class="ml-1">{{ t('NAV.ALLOTMENT') }}</label>
                            <Select2 :settings="{ width: '100%', placeholder: 'Selecione um Loteamento' }" id="alot"
                                     name="alot" v-model="allotment_id"
                                     :options="allotments" :disabled="allotments?.length <= 1"/>
                            <div class="validation" v-if="isAllotmentInvalid">{{
                                    t('GENERAL.MSG.REQUIRED_FIELD')
                                }}
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                            <label class="ml-1 required">{{ t('LOTS.APPLY_IN') }}:</label>
                            <Select2 :settings="{ width: '100%', placeholder: 'Selecione' }" id="apllyIn"
                                     name="apllyIn" v-model="applyType"
                                     :options="optionsApply" @select="changeAllotment()" :disabled="!allotment_id"/>
                            <div class="validation" v-if="isApplyInvalid">{{ t('GENERAL.MSG.REQUIRED_FIELD') }}</div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                            <label class="ml-1 required">{{ t('LOTS.STATUS_FROM') }}:</label>
                            <Select2 :settings="{ width: '100%', placeholder: 'Selecione' }" id="from_status"
                                     name="from_status" v-model="properties_status_id_from"
                                     :options="statusFrom" @select="changeStatusFrom($event)"
                                     :disabled="!allotment_id || !applyType"/>
                            <div class="validation" v-if="fromStatusInvalid">{{ t('GENERAL.MSG.REQUIRED_FIELD') }}</div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                            <label class="ml-1 required">{{ t('LOTS.STATUS_TO') }}:</label>
                            <Select2 :settings="{ width: '100%', placeholder: 'Selecione' }" id="for_status"
                                     name="for_status" v-model="properties_status_id_to"
                                     :options="statusToOptions"
                                     :disabled="!statusToOptions?.length"/>
                            <div class="validation" v-if="toStatusInvalid">{{ t('GENERAL.MSG.REQUIRED_FIELD') }}</div>
                        </div>
                    </div>
                </div>
                <div v-if="applyType === '0'" class="accordion toggle-icons" id="toggleIconsAllotment">
                    <div class="accordion-container">
                        <div class="accordion-header" id="toggleIconsAllotments">
                            <a data-toggle="collapse" :data-target="'#toggleIconsCollapseM' + allotment.text"
                               :id="`openBlock${allotment.text}`"
                               :aria-controls="'toggleIconsCollapse' + allotment.text"
                               @click="allotment.open = !allotment.open" style="cursor: default">
                                {{ allotment.text }}
                            </a>
                        </div>
                    </div>
                </div>
                <div v-if="applyType === '1'">
                    <div v-if="!blocks?.validations">
                        <div v-for="(block, key) in blocks" :key="key">
                            <div class="accordion toggle-icons" :id="`toggleIconsBlock${block.text}`">
                                <div class="accordion-container">
                                    <div class="accordion-header" :id="`toggleIconsBlocks${block.text}`">
                                        <div class="row">
                                            <a data-toggle="collapse"
                                               :data-target="`#toggleIconsCollapseAllotment${block.text}`"
                                               :id="`openBlock${block.text}`"
                                               class="col-6"
                                               aria-controls="toggleIconsCollapseAllotment">
                                                {{ t('PROPERTIES.BLOCK') }} {{ block.text }}
                                            </a>
                                            <div class="col-3"></div>
                                            <div class="custom-control custom-switch list-primary text-right col-3">
                                                <input type="checkbox" class="custom-control-input" :value="block.id"
                                                       v-model="blockSelect"
                                                       :id="`customSwitch${key}`">
                                                <label class="custom-control-label mr-2"
                                                       :for="`customSwitch${key}`"
                                                       :style="isInvalidSelected ? 'color: red': ''">Selecionar</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="blocks?.validations" class="card">
                        <div class="card-body d-flex justify-content-center"><h6>Nenhuma Quadra foi Encontrada</h6>
                        </div>
                    </div>
                    <div v-if="!blocks.length && !blocks?.validations" class="card">
                        <div class="card-body d-flex justify-content-center"><h6>Selecione um Status</h6></div>
                    </div>
                </div>
                <div v-if="applyType === '2'">
                    <div v-if="!blocks?.validations">
                        <div v-for="(block, key) in blocks" :key="block">
                            <div class="accordion toggle-icons" id="toggleIcons">
                                <div class="accordion-container">
                                    <div class="accordion-header" :id="'toggleIcons' + block.title">
                                        <a data-toggle="collapse" :data-target="'#toggleIconsCollapseM' + block.title"
                                           :id="`openBlock${block.text}`"
                                           :aria-expanded="blocks[key].open ? true :  false"
                                           :aria-controls="'toggleIconsCollapseM' + block.title"
                                           @click="indexLotsByAllotment(allotment_id, block.text); blocks[key].open = !blocks[key].open"
                                           :style="isInvalidSelected ? 'color: red': ''">
                                            {{ t('PROPERTIES.BLOCK') }} {{ block.text }}
                                        </a>
                                    </div>
                                    <div :id="'toggleIconsCollapseM' + block.title" class="collapse"
                                         :aria-labelledby="'toggleIcons' + block.title"
                                         :data-parent="'#toggleIcons' + block.title">
                                        <div class="accordion-body">
                                            <div class="text-center"
                                                 v-if="isLoadingLots && (isLoadingLots.block == block.text && isLoadingLots.isLoading)">
                                                <div class="spinner-border m" role="status">
                                                    <span class="sr-only">Loading...</span>
                                                </div>
                                            </div>
                                            <div class="row gutters">
                                                <div v-for="lot in lots[block.text]" :key="lot.id"
                                                     class="col-xl-2 col-lg-4 col-md-6 col-sm-6 col-6 text-center position-relative">
                                                    <div class="d-flex h-100">
                                                        <i v-if="lot.selected" class="icon-check-circle"
                                                           style="position: absolute; font-size: 20px; z-index: 2"></i>
                                                        <div class="card w-100" :id="lot.id" @click="selectLot(lot)"
                                                             :style="[lot.selected ? styleSelect :'opacity: 0.4; max-height: 85%;', `background-color: ${lot.status.backgroud_collor}`, 'cursor: pointer']">
                                                            <h3 class="mt-1" :style="{'color': lot.status.text_collor}">
                                                                {{
                                                                    lot.name
                                                                }}</h3>
                                                            <p class="hide-text"
                                                               :style="{'color': lot.status.text_collor}">
                                                                {{ lot.properties_type_name }}</p>
                                                            <p :style="{'color': lot.status.text_collor}">{{
                                                                    lot.total_area
                                                                }} m²</p>
                                                            <p class="font-weight-bold"
                                                               :style="{'color': lot.status.text_collor}">
                                                                {{ toCurrency(lot.price) }}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="blocks?.validations" class="card">
                        <div class="card-body d-flex justify-content-center"><h6>Nenhuma Quadra foi Encontrada</h6>
                        </div>
                    </div>
                    <div v-if="!blocks.length && !blocks?.validations" class="card">
                        <div class="card-body d-flex justify-content-center"><h6>Selecione um Status</h6></div>
                    </div>
                </div>
            </div>
            <div class="modal-footer justify-content-end" style="margin-top: -1rem">
                <div>
                    <button class="btn btn-secondary" data-dismiss="modal">{{ t('ACTIONS.CLOSE') }}
                    </button>
                </div>
                <div>
                    <button class="btn btn-primary" @click="validateAba">{{ t('LOTS.APPLY') }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {useToast} from "vue-toastification";
import {useI18n} from "vue-i18n";
import Select2 from 'vue3-select2-component';
import Lots from "@/services/Lots";
import {Money3Directive} from 'v-money3';
import Properties from '../../services/Properties';

export default {
    name: "alterStatusLotInMassComponent",
    directives: {money3: Money3Directive},
    setup() {
        const toast = useToast();
        const {t} = useI18n();
        return {t, toast}
    },
    components: {
        Select2
    },
    props: {
        propertiesStatus: {
            type: Array,
            required: true,
        },
        propertiesType: {
            type: Array,
            required: true,
        },
        statusTo: {
            type: Array,
            required: true,
        },
        statusFrom: {
            type: Array,
            required: true,
        },
        allotmentIndex: {
            type: Object,
            required: true
        },
        allotmentIdIndex: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            fromStatusInvalid: null,
            toStatusInvalid: null,
            statusToOptions: [],
            properties_status_id_from: null,
            properties_status_id_to: null,
            allotments: null,
            allotment_id: null,
            allotment: null,
            typeSelected: [],
            isInvalidSelected: null,
            lots: [],
            lotsSelected: [],
            isLoadingLots: null,
            isValueInvalid: false,
            isAllotmentInvalid: false,
            isApplyInvalid: false,
            blocks: null,
            blockSelect: [],
            styleSelect: 'opacity: 1;',
            applyType: null,
            optionsApply: [
                {
                    id: 0,
                    text: 'Loteamento'
                },
                {
                    id: 1,
                    text: 'Quadra'
                },
                {
                    id: 2,
                    text: 'Lote'
                }
            ]
        }
    },
    mounted() {
        this.allotments = this.allotmentIndex;
        this.allotment_id = this.allotmentIdIndex;
    },
    watch: {
        allotment_id: function () {
            this.clearFields();
        }
    },
    methods: {
        async indexLotsByAllotment(allotment_id, block_id = false) {
            if (block_id !== false && !this.lots[block_id]) {
                this.isLoadingLots = {block: block_id, isLoading: true};
                this.lots[block_id] = [];
                await Lots.lotsByAllotmentAndBlock(allotment_id, block_id,this.properties_status_id_from).then(resp => {
                    this.lots[block_id] = [];
                    if (resp.data?.lots?.length) {
                        const lots = resp.data.lots;
                        lots.forEach(i => {
                            i.status = this.propertiesStatus.find(s => s.id === i.properties_status_id);
                            i.properties_type_name = this.propertiesType.find(t => t.id === i.properties_type_id).text;
                        });
                        lots.forEach(item => {
                            if (item.status.type === 4 || item.status.type === 0) {
                                this.lots[block_id].push(item)
                            }
                        })
                    }
                    this.isLoadingLots.isLoading = false;
                }).catch(error => {
                    this.errorMsg(error);
                    this.isLoadingLots.isLoading = false;
                });
            }
        },

        toCurrency(value) {
            return Intl.NumberFormat('pt-br', {style: 'currency', currency: 'BRL'}).format(value);
        },
        changeStatusFrom(status) {
            this.lots = [];
            if (status.type === 0) {
                this.statusToOptions = this.statusTo.filter(i => {
                    if (i.type === 4 && i.can_select) {
                        return true;
                    }
                });
                if (this.statusToOptions.length === 1) {
                    this.properties_status_id_to = this.statusToOptions[0].id
                }
            }
            if (status.type === 4 && status.can_select) {
                this.statusToOptions = this.statusTo.filter(i => {
                    if (i.type === 0) {
                        return true;
                    }
                });
                if (this.statusToOptions.length === 1) {
                    this.properties_status_id_to = this.statusToOptions[0].id
                }
            }
            if (status.type === 4 && !status.can_select) {
                this.statusToOptions = this.statusTo;
                this.properties_status_id_to = null;
            }

            if (this.applyType === '2' || this.applyType === '1') {
                this.indexBlocks(status.id);
            }
        },
        changeAllotment() {
            this.properties_status_id_from = null;
            this.properties_status_id_to = null;
            if (this.applyType === '1') {
                this.blocks = [];
            }
            if (this.applyType === '2') {
                this.blocks = [];
            } else {
                this.allotments.forEach(item => {
                    if (item.id === this.allotment_id) this.allotment = item;
                })
            }
        },

        async indexBlocks(idStatus = null) {
            this.$store.commit('changeLoading', true);
            await Lots.blocksList(this.allotment_id, null, idStatus).then(resp => {
                this.blocks = resp.data;
            }).catch(error => {
                this.errorMsg(error);
            });

            if (!this.blocks.validations) {
                this.blocks.forEach(block => {
                    block.title = block.text.replaceAll(" ", "");
                });
                this.blocks.sort((a, b) => a.text < b.text ? -1 : a.text > b.text ? 1 : 0);
                this.indexLotsByAllotment(this.allotment_id)
            }
            this.$store.commit('changeLoading', false);
        },

        selectLot(lot) {
            if (this.lotsSelected.includes(lot)) {
                this.lotsSelected = this.lotsSelected.filter(item => item !== lot);
            } else this.lotsSelected.push(lot)
            lot.selected = !lot.selected;
        },

        errorMsg(error) {
            const erro = error.response.data.validations.errors;

            if (erro.sql || !erro) {
                this.toast.error(error.response.data.validations.message, {
                    timeout: false,
                });
            } else {
                for (let column in erro) {
                    for (let msg in erro[column]) {
                        this.toast.error(erro[column][msg], {timeout: false});
                    }
                }
            }
        },
        validateAba() {
            let next = true;
            if (!this.lotsSelected.length && !this.typeSelected.length && !this.blockSelect.length && !this.allotment) {
                next = false;
                this.isInvalidSelected = true;
            } else this.isInvalidSelected = false;

            if (!this.properties_status_id_to) {
                this.toStatusInvalid = true;
                next = false;
            } else this.toStatusInvalid = false;

            if (!this.properties_status_id_from) {
                this.fromStatusInvalid = true;
                next = false;
            } else this.fromStatusInvalid = false;

            if (this.applyType === null) {
                this.isApplyInvalid = true;
                next = false;
            } else this.isApplyInvalid = false;

            if (!this.allotment_id) {
                this.isAllotmentInvalid = true;
                next = false;
            }

            this.isAllotmentInvalid = false;
            if (next) this.switchTypeAlter();
        },
        alterStatusByAllotment(data) {
            this.$store.commit('changeLoading', true);
            Properties.alterStatusByAllotment(data).then(resp => {
                document.getElementById('closeX').click();
                this.clearFields();
                this.toast.success(resp.data.validations.message);
                this.$store.commit('changeLoading', false);
                this.$emit('updated');
            }).catch((err) => {
                this.errorMsg(err);
                this.$store.commit('changeLoading', false);
            });
        },
        alterStatusByBlock(data) {
            data.blocks = [];
            this.$store.commit('changeLoading', true);
            this.blockSelect.forEach(item => {
                this.blocks.forEach(block => {
                    if (item === block.id) data.blocks.push({name: block.text})
                })
            });
            Properties.alterStatusByBlock(data).then(resp => {
                document.getElementById('closeX').click();
                this.clearFields();
                this.toast.success(resp.data.validations.message);
                this.$store.commit('changeLoading', false);
                this.$emit('updated');
            }).catch((err) => {
                this.errorMsg(err);
                this.$store.commit('changeLoading', false);
            });
        },
        alterStatusByProperty(data) {
            data.properties = [];
            this.$store.commit('changeLoading', true);
            this.lotsSelected.forEach(item => {
                data.properties.push({id: item.id});
            })
            Properties.alterStatusByProperty(data).then(resp => {
                document.getElementById('closeX').click();
                this.clearFields();
                this.toast.success(resp.data.validations.message);
                this.$store.commit('changeLoading', false);
                this.$emit('updated');
            }).catch((err) => {
                this.errorMsg(err);
                this.$store.commit('changeLoading', false);
            });
        },
        clearFields() {
            this.lots = [];
            this.blocks = null;
            this.blockSelect = [];
            this.typeSelected = [];
            this.applyType = null;
            this.lotsSelected = [];
        },
        switchTypeAlter() {
            switch (this.applyType) {
                case '0':
                    this.alterStatusByAllotment({
                        properties_status_id_from: this.properties_status_id_from,
                        properties_status_id_to: this.properties_status_id_to,
                        allotment_id: this.allotment_id,
                    });
                    break;
                case '1':
                    this.alterStatusByBlock({
                        properties_status_id_from: this.properties_status_id_from,
                        properties_status_id_to: this.properties_status_id_to,
                        allotment_id: this.allotment_id,
                    });
                    break;
                case '2':
                    this.alterStatusByProperty(
                            {
                                properties_status_id_from: this.properties_status_id_from,
                                properties_status_id_to: this.properties_status_id_to,
                                allotment_id: this.allotment_id,
                            });
                    break;
            }
        }
    }
}
</script>

<style scoped>

</style>